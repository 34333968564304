import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/manage',
    name: 'Manage',
    component: () => import('../views/Manager.vue'),
    redirect: '/manage/home',  // 重定向到主页
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: '系统首页', requiresAuth: true }, component: () => import('../views/manager/Home') },
      { path: 'admin', name: 'Admin', meta: { name: '管理员信息', requiresAuth: true }, component: () => import('../views/manager/Admin') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: '个人信息', requiresAuth: true }, component: () => import('../views/manager/AdminPerson') },
      { path: 'password', name: 'Password', meta: { name: '修改密码', requiresAuth: true }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: '公告信息', requiresAuth: true }, component: () => import('../views/manager/Notice') },
      { path: 'user', name: 'User', meta: { name: '用户信息', requiresAuth: true }, component: () => import('../views/manager/User') },
      { path: 'category', name: 'Category', meta: { name: '博客分类', requiresAuth: true }, component: () => import('../views/manager/Category') },
      { path: 'blog', name: 'Blog', meta: { name: '博客信息', requiresAuth: true }, component: () => import('../views/manager/Blog') },
      { path: 'activity', name: 'Activity', meta: { name: '活动信息', requiresAuth: true }, component: () => import('../views/manager/Activity') },
      { path: 'activitySign', name: 'activitySign', meta: { name: '活动报名', requiresAuth: true }, component: () => import('../views/manager/ActivitySign') },
      { path: 'comment', name: 'Comment', meta: { name: '评论信息', requiresAuth: true }, component: () => import('../views/manager/Comment') },
    ]
  },
  {
    path: '/front',
    name: 'Front',
    component: () => import('../views/Front.vue'),
    children: [
      { path: 'home', name: 'Home', meta: { name: '系统首页' }, component: () => import('../views/front/Home') },
      { path: 'person', name: 'Person', meta: { name: '个人信息' }, component: () => import('../views/front/Person') },
      { path: 'detail', name: 'detail', meta: { name: '文章内容' }, component: () => import('../views/front/detail') },
      { path: 'activity', name: 'activity', meta: { name: '活动首页' }, component: () => import('../views/front/activity') },
      { path: 'activityDetail', name: 'activityDetail', meta: { name: '活动内容' }, component: () => import('../views/front/activityDetail') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/register', name: 'Register', meta: { name: '注册' }, component: () => import('../views/Register.vue') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
router.beforeEach((to ,from, next) => {
  let user = JSON.parse(localStorage.getItem("xm-user") || '{}');


  if (to.path === '/') {
    if (user.role) {
      if (user.role === 'USER') {
        next('/#/front/home');
      } else {
        next('/#/manage/admin');
      }
    } else {
      next('/#/login');
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user.role === 'ADMIN') {
      next();
    } else {
      next('/#/manage/403'); // 没有权限，重定向到 403 页面
    }
  } else {
    next();
  }
})

export default router
